<template>
  <div class="" style="width: 100%;">
    <v-container fluid>
      <v-row>
        <!-- <v-col cols=4 class='d-flex flex-column align-start'>
          <span class="d-flex flex-column">
            <span class="d-flex flex-row align-center">
              <v-btn text class="mr-2" @click="()=>{this.$router.go(-1)}">
                <v-icon dark>mdi-arrow-left</v-icon>
              </v-btn>
              <h1 class="mr-2">Return #{{this.$route.params.id}}</h1>
              <div class="mr-2">
                <span v-if="this.dreturn.status===-1" class="v-btn warning pa-1" style="font-size: 10px">{{parseStatus(this.dreturn.status)}}</span>
                <span v-if="this.dreturn.status===0" class="v-btn orange pa-1" style="font-size: 10px">{{parseStatus(this.dreturn.status)}}</span>
                <span v-if="this.dreturn.status===1" class="v-btn success pa-1" style="font-size: 10px">{{parseStatus(this.dreturn.status)}}</span>
                <span v-if="this.dreturn.status===2" class="v-btn success pa-1" style="font-size: 10px">{{parseStatus(this.dreturn.status)}}</span>
                <span v-if="this.dreturn.status===3" class="v-btn success pa-1" style="font-size: 10px">{{parseStatus(this.dreturn.status)}}</span>
              </div>
              <v-icon v-if="this.updateStateValue===0" class="ml-2" color="warning">mdi-cloud-refresh</v-icon>
              <v-icon v-if="this.updateStateValue===1" class="ml-2" color="success">mdi-cloud-check</v-icon>
              <v-icon v-if="this.updateStateValue===2" class="ml-2" color="error">mdi-cloud-alert</v-icon>
              <v-progress-circular
                  indeterminate
                  color="green"
                  v-if="initialLoader"
              ></v-progress-circular>
              <v-btn :disabled="!returnOk()" @click="openSignDialog()" color="success" small class="ml-2">Seal</v-btn>
              <v-btn :disabled="!modifyDisabledCriteria" fab color="teal" @click="printReturn" x-small><v-icon>mdi-printer</v-icon></v-btn>
            </span>
            <span>Created By: {{this.lookupUsername(this.dreturn.createdBy)}}</span>
            <span>Created At: {{utils.formatDate(this.dreturn.createdAt)}} at {{utils.formatTime(this.dreturn.createdAt)}}</span>
            <span>Sealed By: {{this.lookupUsername(this.dreturn.sealedBy)}}</span>
            <span>Sealed At: {{utils.formatDate(this.dreturn.sealedAt)}} at {{utils.formatTime(this.dreturn.sealedAt)}}</span>
          </span> -->
        <v-col cols="4" class='d-flex flex-column align-start justify-start'>
          <div class="d-flex flex-row align-center">
            <v-btn fab x-small @click="()=>this.$router.go(-1)">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <h2 class="mx-1" style="color: maroon;">Return #{{this.$route.params.id}}</h2>
            <span>
              <span v-if="this.dreturn.status===-1" class="v-btn warning pa-1" style="font-size: 10px">{{parseStatus(this.dreturn.status)}}</span>
              <span v-if="this.dreturn.status===0" class="v-btn orange pa-1" style="font-size: 10px">{{parseStatus(this.dreturn.status)}}</span>
              <span v-if="this.dreturn.status===1" class="v-btn success pa-1" style="font-size: 10px">{{parseStatus(this.dreturn.status)}}</span>
              <span v-if="this.dreturn.status===2" class="v-btn success pa-1" style="font-size: 10px">{{parseStatus(this.dreturn.status)}}</span>
              <span v-if="this.dreturn.status===3" class="v-btn success pa-1" style="font-size: 10px">{{parseStatus(this.dreturn.status)}}</span>
            </span>
            <v-progress-circular
              indeterminate
              color="green"
              v-if="initialLoader"
            ></v-progress-circular>
            <v-icon v-if="this.updateStateValue===0" class="ml-2" color="warning">mdi-cloud-refresh</v-icon>
            <v-icon v-if="this.updateStateValue===1" class="ml-2" color="success">mdi-cloud-check</v-icon>
            <v-icon v-if="this.updateStateValue===2" class="ml-2" color="error">mdi-cloud-alert</v-icon>
          </div>
          <div v-if="!initialLoader" style="width:100%;" outlined class="d-flex flex-column pa-1">
            <v-card outlined>
              <v-card-text class="d-flex flex-column">
              <span>Created By: {{this.lookupUsername(this.dreturn.createdBy)}}</span>
              <span>Created At: {{utils.formatDate(this.dreturn.createdAt)}} at {{utils.formatTime(this.dreturn.createdAt)}}</span>
              <span>Sealed By: {{this.lookupUsername(this.dreturn.sealedBy)}}</span>
              <span>Sealed At: {{utils.formatDate(this.dreturn.sealedAt)}} at {{utils.formatTime(this.dreturn.sealedAt)}}</span>
              </v-card-text>
            </v-card>
            <v-card outlined>
              <v-card-text>
                <v-btn :disabled="!returnOk()" @click="openSignDialog()" dense color="info" small>Seal</v-btn>
                <!-- <confirmedActionButton
                  class="mx-2" 
                  color="warning" 
                  small  
                  buttonText="Void"
                  requireUsername
                  requirePassword
                  :loading="voidLoader"
                  @cb="voidOrder"
                  fabIcon="mdi-close"
                  :disabled="this.invoice.status == -1"
                  :dialogText="'Voiding cannot be undone. Are you sure you wish to proceed?'"
                /> -->
                <!-- <v-btn v-if="isAllowed('return', 'c')" :disabled="this.invoice.status<1" class="mr-2 white--text" dense color="teal" small @click="attemptCreateReturn">Make Return</v-btn> -->
              </v-card-text>
            </v-card>
            <div v-if="getGlobalValue('VEC_PRINT_SYSTEM_CONTAINER_RETURNS')" style="width: 100%;">
              <dynamicButtonContainer :containerId="`${getGlobalValue('VEC_PRINT_SYSTEM_CONTAINER_RETURNS')}`" :data="{status: dreturn.status, id1: $route.params.id}" style="width: 100%;"/>
            </div>
          </div>
          <span class="mt-4 pa-1">
            <v-text-field :disabled="modifyDisabledCriteria" @change="directSearchOrder" dense outlined v-model="directOrderSearch.value" min="0" :loading="directOrderSearch.loading" type="number" clearable label="Order ID"></v-text-field>  
            <v-card outlined class="mb-3">
              <v-card-title class="d-flex flex-row justify-space-between">
                <span class="d-flex flex-row">
                  <h3 style="cursor: pointer;" @click="()=>this.$router.push({path: `/orders/view/${this.dreturn.orderId}`})">Order #{{this.dreturn.orderId|| "-"}}</h3>
                  <v-btn fab x-small color="error" :disabled="modifyDisabledCriteria" @click="clearReturn" class="ml-2"><v-icon>mdi-close</v-icon></v-btn>
                  <v-btn class="ml-2" v-if="this.selectedOrder.Customer" @click="$router.push({path: `/customers/view/${selectedOrder.Customer.id}`})" fab x-small color="warning"><v-icon>mdi-account-eye-outline</v-icon></v-btn>
                </span>
                <span v-if="this.selectedOrder.Customer" class="d-flex flex-row justify-start align-center">
                  <span style="cursor: pointer;" @click="()=>this.$router.push({path: `/customers/view/${selectedOrder.Customer.id}`})" class="d-flex flex-column">
                    <h4 style="margin-bottom:-10px; text-align: left;">Customer</h4>
                    <h3 style="text-align: left;">{{selectedOrder.Customer.name}}</h3>
                    <span v-if="selectedOrder.Customer.phone"><b>Phone: </b>{{selectedOrder.Customer.phone}}</span>
                  </span>
                </span>
              </v-card-title>
              <v-card-text>
                <span v-if="this.selectedOrder">
                  <div v-for="item in this.selectedOrder.OrderLineItems" :key="item.id">
                    <div class="d-flex flex-column justify-space-between" style="align-text: left; background-color: rgba(0,0,0,0.05); padding: 10px; margin-top: 6px; border-radius: 7px;">
                      <span class="d-flex flex-row justify-space-between">
                        <h3 style="text-align: left;">{{item.productName}}</h3>
                        <v-btn x-small :disabled="addReturnLineItemDisabled(item)" :loading="item.loading" fab color="success" @click="addReturnLineItem(item)"><v-icon>mdi-plus</v-icon></v-btn>
                      </span>
                      <span class="d-flex flex-column align-start">
                        <span><b>Unit Price: ${{item.unitPrice}}</b></span>
                        <span><b>Qt. Purchased: {{item.quantity}}</b></span>
                        <span><b>Qt. Already Returned: {{item.returnedQt|| 0}}</b></span>
                        <!-- <p>{{item}}</p> -->
                        <span>ID: {{item.productId}}, SKU:{{item.sku}}</span>
                      </span>
                    </div>
                  </div>
                </span>
              </v-card-text>
            </v-card>
          </span>
        </v-col>
        <v-col cols=8>
          <v-card v-if="dreturn.CreditNote" class="mb-2" outlined>
            <v-card-title class="d-flex flex-row justify-space-between">
              <h3>Credit Note # {{ dreturn.CreditNote.id }}</h3>
            </v-card-title>
            <v-card-text class="d-flex flex-column">
              <span>Amount: {{utils.formatCurrency(dreturn.CreditNote.amount)}}</span>
              <span>Created At: {{utils.formatDate(dreturn.CreditNote.createdAt, 'withTime')}}</span>
              <span>Created By: {{lookupUsername(dreturn.CreditNote.createdBy)}}</span>
              <span>Expires At: {{utils.formatDate(dreturn.CreditNote.expiresAt)}}</span>
              <span>Status: 
                <span v-if="dreturn.CreditNote.status===-1" class="v-btn warning pa-1" style="font-size: 10px">{{utils.parseCNStatus(dreturn.CreditNote.status)}}</span>
                <span v-if="dreturn.CreditNote.status===0" class="v-btn orange pa-1" style="font-size: 10px">{{utils.parseCNStatus(dreturn.CreditNote.status)}}</span>
                <span v-if="dreturn.CreditNote.status===1" class="v-btn info pa-1" style="font-size: 10px">{{utils.parseCNStatus(dreturn.CreditNote.status)}}</span>
                <span v-if="dreturn.CreditNote.status===2" class="v-btn success pa-1" style="font-size: 10px">{{utils.parseCNStatus(dreturn.CreditNote.status)}}</span>
              </span>
              <span>Notes: {{dreturn.CreditNote.notes||'-'}}</span>
            </v-card-text>
            <v-card-actions>
              <v-btn color="info" :loading="printCNLoader" text small @click="printCN(dreturn.CreditNote.id)">Print CN</v-btn>
              <confirmedActionButton
                class="mx-2" 
                color="warning" 
                small
                text  
                buttonText="Redeem For Cash"
                requireUsername
                requirePassword
                :loading="cnRedeemLoader"
                @cb="redeemCNForCash"
                fabIcon="mdi-close"
                :disabled="dreturn.CreditNote.status != 1"
                :dialogText="'Redeeming a CN for cash cannot be undone. Are you sure you wish to proceed?'"
                />
            </v-card-actions>
          </v-card>
          <span class="mb-2">
            <h3 v-if="dreturn.status && dreturn.status==0">This will create a CN for {{this.utils.formatCurrency(dreturn.totalValue)}}</h3>
            <!-- <span class="d-flex flex-row">
              <h3 style="color: green;">This return created a CN for {{this.utils.formatCurrency(dreturn.totalValue)}}</h3>
              <v-btn v-if="selectedOrder" class="ml-2" color="info" fab x-small @click="()=>this.$router.push({path: `/customers/view/${selectedOrder.Customer.id}`})">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </span> -->
          </span>
          <v-card outlined>
            <v-card-title class="d-flex flex-row justify-space-between">
              <h3>Returned Items ({{this.dreturn.ReturnLineItems?this.dreturn.ReturnLineItems.length:0}})</h3>
              <span class="d-flex flex-column">
                <span class="d-flex flex-row">
                  <h5>Subtotal: {{utils.formatCurrency(this.computedSubtotal)|| 0}}</h5>
                  <v-btn 
                    :disabled="parseFloat(this.dreturn.totalValue) == parseFloat(this.computedSubtotal)" 
                    fab
                    x-small
                    class="ml-2"
                    color="info"
                    @click="()=>{this.dreturn.totalValue = parseFloat(this.computedSubtotal); this.updateReturn()}"
                  >
                    <v-icon>mdi-arrow-down</v-icon>
                  </v-btn>
                </span>
                <h4 class="d-flex flex-row">
                  Total: 
                  <v-text-field :disabled="this.modifyDisabledCriteria" class="ml-2" dense outlined type="number" v-model="dreturn.totalValue" @change="updateReturn()"></v-text-field>
                </h4>
              </span>
            </v-card-title>
            <v-card-text>
              <div v-for="item in this.dreturn.ReturnLineItems" :key="item.id">
                <div class="d-flex flex-column justify-space-between" style="align-text: left; background-color: rgba(0,0,0,0.05); padding: 10px; margin-top: 6px; border-radius: 7px;">
                  <span class="d-flex flex-row justify-space-between">
                    <h3 style="text-align: left;">{{item.Product.name}} | ID: {{item.productId}}</h3>
                    <v-btn x-small :disabled="modifyDisabledCriteria" fab color="error" @click="removeReturnLineItem(item.id)"><v-icon>mdi-close</v-icon></v-btn>
                  </span>
                  <span class="d-flex flex-row align-center">
                    <span class="d-flex flex-row align-center justify-center">
                      <v-text-field :disabled="modifyDisabledCriteria" @change="updateReturnLineItem(item)" class="mr-2" type="number" dense outlined v-model="item.quantity" label="Quantity"></v-text-field>
                      <v-text-field :disabled="modifyDisabledCriteria" @change="updateReturnLineItem(item)" dense outlined v-model="item.returnValue" type="number" label="Value"></v-text-field>
                      <h4 class="ml-3">Line Total: {{item.quantity*item.returnValue}}</h4>
                    </span> 
                  </span>
                  <span class="d-flex flex-row align-center">
                    <!-- <v-checkbox :disabled="modifyDisabledCriteria" @change="updateReturnLineItem(item)" v-model="item.isReplacement" label="Replacement ? "></v-checkbox> -->
                    <v-checkbox :disabled="modifyDisabledCriteria" @change="updateReturnLineItem(item)" v-model="item.addedToInventory" class="ml-5" label="Added back to inventory ?"></v-checkbox>
                  </span>
                  <v-textarea :disabled="modifyDisabledCriteria" @change="updateReturnLineItem(item)" dense outlined label="Notes" v-model="item.notes"></v-textarea>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog scrollable v-model="searchCustomerDialog.isOpen" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Search Customer
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <v-text-field :loading="searchCustomerDialog.loader" ref="customerDialogPhone" v-model="customer.phone" @keyup="findCustomerByPhone" label="Phone" outlined></v-text-field>
          <v-chip style="margin-bottom: 20px; margin-right: 5px;" v-for="suggestion in customer.suggestedMatches" :key="suggestion.id" @click="setCustomerAndClose(suggestion)">
            <span>{{suggestion.name}} - {{suggestion.phone}}</span>
          </v-chip>
          <v-text-field :loading="findCustomerByNameLoader" ref="customerDialogName" v-model="customer.name" @keyup="findCustomerByName" label="Name" outlined></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeSearchCustomerDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="signDialog.isOpen"
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you wish to sign this Return Form?
        </v-card-title>
        <v-card-text>Please ensure all fields are correct. Your identity will be linked to this document.</v-card-text>
        <v-card-text>Enter your password to confirm.</v-card-text>
        <v-text-field label="Password" placeholdler="Password" type="password" outlined style="width: 300px; margin: 20px;" v-model="signDialog.password"></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="closeSignDialog()"
          >
            Cancel
          </v-btn>
          <v-btn
            color="error"
            :loading="this.signDialog.loading"
            :disabled="!this.signDialog.password"
            @click="attemptSign()"
          >
            Confirm Signature
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="confirmRedeemDialog.isOpen"
        width="600"
    >
      <v-card>
        <v-card-title>
          Confirm Register Bypass
        </v-card-title>
        <v-card-text>
          <span>You currently don't have a register opened at the moment. Are you sure you want to continue with redeeming for cash before opening a register?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cancelRedeemConfirm">Close</v-btn>
          <v-btn color="success" @click="confirmRedeem">Redeem For Cash Without a Register</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
</style>
<script>
import axios from 'axios';
import {mapGetters, mapMutations} from 'vuex'
import utils from  '../../plugins/helpers'
import scanSystem from "../../plugins/scanSystem"
import dynamicButtonContainer from './../../components/dynamicButtonContainer.vue';
import confirmedActionButton from './../../components/confirmedActionButton.vue';
import _ from 'lodash';
export default {
  components: {
    dynamicButtonContainer,
    confirmedActionButton

  },
  data () {
    return {
      utils: utils,
      loader: false,
      initialLoader: true,
      findCustomerByPhoneLoader: false,
      findCustomerByNameLoader: false,
      numUpdates: 0,
      printCNLoader: false,
      cnRedeemLoader: false,
      updateStateValue: 1, // 0 = updating; 1 = updateSuccess; 2 = updateFail
      locationLoading: false,
      snackObj: {
        state: false,
        color: '',
        text: ''
      },
      printReturnLoader: false,
      singular: "Return",
      singularLower: "return",
      plural: "Returns",
      pluralLower: "returns",
      dreturn: {},
      searchCustomerDialog: {
        isOpen: false,
        term: '',
        results: [],
        loader: false,
      },   
      selectedCustomer: {},
      selectedOrder: {},
      purchaseTable: {
        headers: [
          {text: 'Order #', align: 'start', value: 'id'},
          { text: 'Date Created', value: 'createdAt' },
          { text: 'Grand Total', value: 'metadata.grandTotal' },
        ],
        items: []
      },
      adjuster: 0,
      signDialog: {
        isOpen: false,
        password: '',
        loading: false,
      },
      customer: {},
      directOrderSearch: {
        value: '',
        loading: false
      },

      confirmRedeemLoading: false,
      confirmRedeemCredentials: null,
      confirmRedeemDialog: {
        isOpen: false,
      },
    }
  },
  created(){
    this.debouncedUpdateReturn = _.debounce(this.updateReturn, 500)
  },
  async mounted(){
    try{
      this.initialLoader = true;
      let res = await axios.get(`${this.getEndpoint}/api/returns/${this.$route.params.id}`)
      if(res.data.error) throw res.data.error
      this.dreturn = res.data.data

      if(this.dreturn.Order){
        this.selectedOrder = this.dreturn.Order
        this.directOrderSearch.value = this.dreturn.orderId
        for(let i of this.selectedOrder.Returns){
          for(let j of i.ReturnLineItems){
            let q = this.selectedOrder.OrderLineItems.find(x=>x.productId==j.productId)
            if(!q.returnedQt) q.returnedQt = 0
            q.returnedQt += j.quantity 
          }
        }
      }

      this.selectedOrder?.OrderLineItems?.forEach(x=>x.loading = false)

      if(!this.dreturn.totalValue)
        this.dreturn.totalValue = this.computedSubtotal
    
      if(!this.dreturn.addedToInventory)
        this.dreturn.addedToInventory = false

      console.log(this.dreturn)
      //return has its own scansense
      window.addEventListener('keypress', (e)=>{
        if(this.scanBus.locked && this.scanBus.type=="return"){
          this.cancelClearScanStringTimeout()
          this.clearScanStringTimeout()
          if(this.keyShortcutMode){
            this.cancelClearScanStringTimeout()
            if(e.key==='/'){
              this.endShortcut()
            }else{
              if(e.key==='Enter'){
                console.log(this.scanString)
                this.handleShortcut(this.scanString)
              }else{
                if(!this.scanString) this.scanString = ""
                this.scanString = this.scanString + e.key
              }
            }
          }else{
            if(e.key==='/'){
              if(!this.scanString){
                this.cancelClearScanStringTimeout()
                this.keyShortcutMode = true
              }else{
                this.scanString = this.scanString + e.key
              }
            }else{
              if(e.key==='Enter'){
                let validatedType = scanSystem.validateType(this.scanString)
                if(validatedType){
                  this.scanInProgress = true
                  this.handleScan(this.scanBus.type)
                  this.clearScanString
                  this.setScanBus('return')//important for this return specific thing
                }else{
                  this.clearScanString
                  this.resetScanBus()
                  this.setScanBus('return')
                }
              }else{
                this.scanInProgress = false
                if(!this.scanString) this.scanString = ""
                this.scanString = this.scanString + e.key
              }
            }
          }
        }
      })
    }
    catch (error) {
      console.error(error)
      this.snack(error.msg || error.msg?.message || error, "error");
    }
    finally {
      this.initialLoader = false;
      console.log("Done Mounting")
    }
  },
  computed: {
    ...mapGetters(['getId', 'getEndpoint', "isAllowed", 'isManaged', 'lookupUsername', 'scanBus', 'getGlobalValue']),
    modifyDisabledCriteria(){
      if(this.dreturn)
        return parseInt(this.dreturn.status) !== 0
      return 0
    },
    isSealable(){
      console.log(this.dreturn)
    return false
    },
    computedSubtotal(){
      return this.dreturn?.ReturnLineItems ? this.dreturn.ReturnLineItems.reduce((acc,x)=>acc+(parseFloat(x.returnValue)*parseInt(x.quantity)),0):0
    },
  },
  methods: {
    ...mapMutations([
      'setScanBus',
      'resetScanBus',
      'updateCurrentRegister'
    ]),
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    cancelRedeemConfirm(){
      this.paymentToConfirmType = "";
      this.confirmRedeemDialog.isOpen = false;
    },
    confirmRedeem(){
      this.confirmRedeemDialog.bypass = true;
      this.redeemCNForCashConfirmed(this.confirmRedeemCredentials)

      this.cancelRedeemConfirm()
    },
    async redeemCNForCash(type){
      try{
        this.confirmRedeemLoading = true;

        if(this.getGlobalValue('registerModuleEnabled')!=='true'){
          await this.redeemCNForCashConfirmed(type)
          return;
        }
        this.confirmRedeemCredentials = type;

        let res = await axios.get(`${this.getEndpoint}/api/registers/checkPaymentBypass`)
        if(res.data.error) throw res.data.error
        if(!res.data.data.prompt){
          await this.redeemCNForCashConfirmed(type)
          return;
        }
        this.confirmRedeemDialog.isOpen = true;
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error")
      } finally {
        this.confirmRedeemLoading = false
      }
    },
    async redeemCNForCashConfirmed(obj){
      try{
        this.cnRedeemLoader = true;

        let res = await axios.post(`${this.getEndpoint}/api/creditNotes/cashRedeem/${this.dreturn.CreditNote.id}`, obj)
        if(res.data.error) throw res.data.error;
        this.snack("✅ Success.")
        this.dreturn.CreditNote.status = 2;
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error")
      }
      finally{
        this.cnRedeemLoader = false;
      }
    },
    async printCN(id){
      try {
        this.printCNLoader = true
        let x = await axios.post(`${this.getEndpoint}/api/custom/print/creditnote/${id}`)
        if(x.data.error) throw x.data.error
        let printWindow = open("","Printing")
        printWindow.document.write("")
        printWindow.document.write(x.data.data.job.htmlToPrint)
        printWindow.setTimeout(()=>{
          printWindow.print()
          printWindow.document.write("")
          printWindow.close()
        },500)
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error")
      } finally {
        this.printCNLoader = false
      }
    },
    parseStatus(id){
      if(id===-1) return "Voided"
      else if(id===0) return "Draft"
      else if(id===1) return "Sealed"
    },
    addReturnLineItemDisabled(item){
      if(this.modifyDisabledCriteria)
        return true
      if(this.dreturn.ReturnLineItems.find(x=>x.productId==item.productId))
        return true
      if(item.quantity <= item.returnedQt)
        return true
      return false
    },
    resetSearchCustomerDialog(){
      this.customer = {}
      this.searchCustomerDialog.term = ''
      this.searchCustomerDialog.results = []
      this.searchCustomerDialog.loader = false
    },
    openSearchCustomerDialog(){
      this.searchCustomerDialog.isOpen = true
    },
    closeSearchCustomerDialog(){
      this.resetSearchCustomerDialog()
      this.searchCustomerDialog.isOpen = false
    },
    async findCustomerByPhone(){
      try {
        if(!this.customer?.phone){
          this.customer.suggestedMatches = []
          return
        }
        if(this.customer?.phone?.length < 3) return
        this.findCustomerByPhoneLoader = true
        let res = await axios.get(`${this.getEndpoint}/api/customers/byPhone/${this.customer.phone}`)
        this.customer.suggestedMatches = res.data.data
        this.findCustomerByPhoneLoader = false
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error")
        this.findCustomerByPhoneLoader = false
      }
    },
    async findCustomerByName(){
      try {
        if(!this.customer?.name){
          this.customer.suggestedMatches = []
          return
        }
        if(this.customer?.name?.length < 3) return
        this.findCustomerByNameLoader = true
        let res = await axios.get(`${this.getEndpoint}/api/customers/byName/${this.customer.name}`)
        this.customer.suggestedMatches = res.data.data
        this.findCustomerByNameLoader = false
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error")
        this.findCustomerByNameLoader = false
      }
    },
    async updateReturn(){
      try {
        if(this.modifyDisabledCriteria) return // very important it must be at the top
        if(this.initialLoader) return false
        if(this.dreturn && this.dreturn.status > 0){
          throw "❌ Return already Sealed."
        }
        this.updateStateValue = 0
        this.dreturn.totalValue
        let res = await axios.put(`${this.getEndpoint}/api/returns/${this.$route.params.id}`, this.dreturn)
        console.log("Update Request sent out.")
        if(res.data.error) throw res.data.error
        this.updateStateValue = 1
        this.returnOk()
        // res
      } catch (error) {
        this.updateStateValue = 2
        console.error(error)
        this.snack(error.msg || error, "error")
      } finally {
        this.loader = false
      }
    },
    clearScanString(){
      this.scanString = ''
      this.scanInProgress = false
      console.log("🔁 Scan String Cleared")
    },
    clearScanStringTimeout(){
      this.timeout = setTimeout(this.clearScanString,500)
    },
    cancelClearScanStringTimeout(){
      clearTimeout(this.timeout)
    },
    async handleScan(type){
      try {
        let s = this.scanString.replace(/\\\/\/\\=/,'')
        let pId = (s.replace(`${type}-`, ''));
        let p;
        pId
        let prod = null
        switch(type){
          case 'INV':
            console.log("Return Invoice Scanned: ", s)
            this.handleScannedInvoice(s.split("-")[1])
            this.scanInProgress = false
            break
          case 'VSID':
            // this.setScanBus({username: this.lookupUsername(pId.split("-")[0]), uId: pId.split("-")[0]})
            // console.log(this.lookupUsername(pId.split("-")[0]))
            p = '/users/view/'+pId.split("-")[0]
            if(this.$route.path!==p)
              await this.$router.push({path: p})
            this.scanInProgress = false
            break
          case 'JT':
            console.log("Job Ticket Scanned: ", s)
            p = `/orders/view/${s.split('-')[1]}`
            if(this.$route.path!==p)
              await this.$router.push({path: p})
            this.scanInProgress = false
            break
          case 'DN':
            console.log("Delivery Note Scanned: ", s)
            p = `/deliveries/view/${s.split('-')[1]}`
            if(this.$route.path!==p)
              await this.$router.push({path: p})
            this.scanInProgress = false
            break
          case 'PL':
            console.log("Product Scanned: ", s) //this
            this.openProductScanDialog()
            prod = await this.lookupProduct((s.split('-')[1]), "ID")
            if(!prod) throw "Product Not In VIMS DB."
            this.productScanDialog.item = prod.data
            this.scanInProgress = false
            break
          case 'EXT':
            console.log("Invoice External Product Scanned: ", s)
            this.openProductScanDialog()
            prod = await this.lookupProduct(s, "SKU")
            if(!prod) throw "External Product Not In VIMS DB."
            this.productScanDialog.item = prod
            this.scanInProgress = false
            break
        }
      } catch (error) {
        console.log(error)
        this.scanInProgress = false
        this.snack(error)
      }
    },
    openProductScanDialog(){
      this.productScanDialog.isOpen = true
      this.productScanDialog.loader = true
    },
    closeProductScanDialog(){
      this.productScanDialog.isOpen = false,
      this.productScanDialog.loader = false
      this.productScanDialog.item = ''
    },
    async lookupProduct(s, type){
      try {
        let res;
        if(type === "SKU")
          res = await axios.get(`${this.getEndpoint}/api/products/bySKU/${s}`)
        if(type === "ID")
          res = await axios.get(`${this.getEndpoint}/api/products/${s}`)
        if(res.data.error) throw res.data.error
        if(!res.data.data) throw 'External barcode is not in DB.'
        return res.data.data
      } catch (error) {
        if(error.customError){
          console.error(error)
          this.snack('External barcode is not in DB.')
        }
        else this.snack(error)
      } finally {
        this.productScanDialog.loader = false
      }
    },
    async handleScannedInvoice(id){
      try {
        let res = await axios.get(`${this.getEndpoint}/api/orders/forReturns/${id}`)
        if(res.data.error) throw res.data.error
        let data = res.data.data
        if(!data) throw "Invoice needs to be sealed."
        if(this.dreturn.customerId){
           if(data.Customer.id !== this.dreturn.customerId) throw "Invoice does not belong to the selected customer."
        }else{
          this.setCustomer(data.Customer)
        }
        await this.loadOrder(id, data)
      } catch (error) {
        console.log(error)
        this.snack(error)
      }
    },
    purchaseTableRowClick(row){
      this.loadOrder(row.id)
    },
    async loadOrder(id){
      try {
        let res = await axios.get(`${this.getEndpoint}/api/orders/forReturns/${id}`)
        if(res.data.error) throw res.data.error
        this.selectedOrder = res.data.data;
        for(let i of this.selectedOrder.Returns){
          for(let j of i.ReturnLineItems){
            let q = this.selectedOrder.OrderLineItems.find(x=>x.productId==j.productId)
            if(!q.returnedQt) q.returnedQt = 0
            q.returnedQt += j.quantity 
          }
        }

        this.dreturn.orderId = this.selectedOrder.id
        await this.updateReturn()
        this.returnOk()
      } catch (error) {
        console.log(error)
        this.snack(error.msg)
        this.directOrderSearch.value = this.dreturn.orderId
      }
    },
    async removeReturnLineItem(e){
      try {
        let res = await axios.delete(`${this.getEndpoint}/api/returns/lineItem/${e}`)
        if(res.data.error) throw res.data.error
        let i = (this.dreturn.ReturnLineItems.findIndex(x=>x.id===e))
        if(i == -1) throw "Item Could Not Be Found. Refresh this page."
        this.dreturn.ReturnLineItems.splice(i,1)
        if(!this.dreturn.ReturnLineItems) this.dreturn.ReturnLineItems = []
        this.returnOk()
      } catch (error) {
        console.log(error)
        this.snack(error.msg)
      }
    },
    async addReturnLineItem(p){
      try {
        this.updateStateValue = 0
        // let res = await axios.get(`${this.getEndpoint}/api/returns/addItemPrecheck/${this.dreturn.customerId}/${p.productId}`)
        // if(!res.data) throw "Could not add item."
        // console.log("Precheck done")
        p.loading = true
        let tempLineItem = {
          returnId: this.$route.params.id,
          productId: p.productId,
          orderId: p.orderId,
          serial: '',
          returnValue: p.unitPrice,
          isReplacement: false,
          notes: '',
          quantity: 1,
          Product: {
            name: p.productName
          }
        }
        let res = await axios.post(`${this.getEndpoint}/api/returns/lineItem/${this.dreturn.id}`, tempLineItem)
        if(res.data.error) throw res.data.error
        this.dreturn?.ReturnLineItems.push(res.data.data)
        this.updateStateValue = 1
        this.returnOk()
      } catch (error) {
        console.log(error)
        this.snack(error)
        this.updateStateValue = 2
      } finally {
        p.loading = false
      }
    },
    async updateReturnLineItem(p){
      try {
        this.updateStateValue = 0
        let res = await axios.put(`${this.getEndpoint}/api/returns/lineItem/${p.id}`, p)
        if(res.data.error) throw res.data.error
        if(!res.data.success) this.snack(`Quantity invalid for item ${p.productId}. Resetting to previous quantity of ${res.data.data.quantity}.`)
        let ix = this.dreturn.ReturnLineItems.findIndex(x=>x.id==p.id)
        ix==-1?null:this.dreturn.ReturnLineItems.splice(ix, 1, res.data.data)
        this.updateStateValue = 1
        this.returnOk()
      } catch (error) {
        console.log(error)
        this.snack(error.msg)
        this.updateStateValue = 2
        p.quantity = 1
      }
    },
    openSignDialog(){
      this.signDialog.isOpen = true
      this.signDialog.loading = false
      this.signDialog.password = ''
    },
    closeSignDialog(){
      this.signDialog.isOpen = false
    },
    async attemptSign(){
      try {
        this.signDialog.loading = true
        let res = await axios.put(`${this.getEndpoint}/api/returns/sign/${this.dreturn.id}`, {password: this.signDialog.password, return: this.dreturn})
        if(res.data.error) throw res.data.error
        this.snack(`✅ Return signed. Created CN # ${res.data.data.cnId} for this return.`)
        this.closeSignDialog()
        this.dreturn.sealedAt = res.data.data.sealedAt
        this.dreturn.sealedBy = res.data.data.sealedBy
        this.dreturn.status = 1
        this.dreturn.CreditNote = res.data.data.cn
        // await axios.get(`https://api.varion.co/stylesheets/default.css`)
        // await axios.get(`https://api.varion.co/stylesheets/invoice.css`)
      } catch (error) {
        console.log(error)
        this.snack(error.msg)
      }finally{
        this.signDialog.loading = false
        this.signDialog.password = ''
      }
    },
    returnOk(){
      if(this.dreturn?.status == 0){ // return is a draft
        console.log(this.dreturn)
        if(!this.dreturn.orderId){
          console.log("!this.dreturn.orderId")
          return false
        }
        if(this.dreturn.totalValue===null){
          console.log("!this.dreturn.totalValue")
          return false
        }
        if(!this.dreturn.ReturnLineItems){
          console.log("!this.dreturn.ReturnLineItems")
          return false
        }
        if(this.dreturn.ReturnLineItems.length < 1){
          console.log("this.dreturn.ReturnLineItems.length < 1")
          return false
        }
        console.log("Return ok final.")
        return true
      }
      console.log("Return not ok final.")
      return false
    },
    async directSearchOrder(){
      try {
        if(this.directOrderSearch.value){
          await this.loadOrder(this.directOrderSearch.value)
        }else{
          this.directOrderSearch.value = this.dreturn.orderId
        }
      } catch (error) {
        this.snack(error)
        console.log(error)
      }
    },
    async clearReturn(){
      try {
        this.directOrderSearch.value = null
        this.dreturn.orderId = null,
        this.dreturn.totalValue = 0,
        this.dreturn.ReturnLineItems = []
        this.selectedCustomer = {}
        this.selectedOrder = {}
        let res = await axios.delete(`${this.getEndpoint}/api/returns/lineItems/${this.dreturn.id}`)
        if(res.data.error) throw res.data.error
        await this.updateReturn()
        this.snack("Order Removed from Return.")
      } catch (error) {
        console.log(error)
        this.snack(error)
      }
    },
    async printReturn(){
      try {
        this.printReturnLoader = true
        let x = await axios.post(`${this.getEndpoint}/api/print/returnWithCreditNote/${this.dreturn.id}`)
        if(x.data.error) throw x.data.error
        console.log(x.data.data)
        let printWindow = open("","Printing")
        printWindow.document.write("")
        printWindow.document.write(x.data.data.job.htmlToPrint)
        printWindow.setTimeout(()=>{
          printWindow.print()
          printWindow.document.write("")
          printWindow.close()
        },500)
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error")
      } finally {
        this.printReturnLoader = false
      }
    },   
  }
}
</script>
